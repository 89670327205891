@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.spin {
  & > svg {
    animation-name: spin;
  }
  &.animated svg {
    animation-duration: var(--animate-duration);
    animation-fill-mode: both;
  }
  &.infinite > svg {
    animation-iteration-count: infinite;
  }
}
